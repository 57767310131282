import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"
import { trigger } from "../../utils/events"

interface SubmitEvent extends CustomEvent {
  detail: { success: boolean }
}

interface FlashPresentEvent extends CustomEvent {
  detail: { flashElement: HTMLElement }
}

// Connects to data-controller="canopy--dialog-component"
export default class extends Controller {
  static targets = ["dialog", "flashPlaceholder"]

  static values = {
    open: {
      default: true,
      type: Boolean,
    },
    closeOnFormSubmit: Boolean,
    closeOnBackdrop: {
      default: true,
      type: Boolean,
    },
    referredFromUrl: Boolean,
    redirectUrl: String,
    // Allows to bind the drawer with a specific param, so it gets added/removed from the
    // URL when the Drawer gets opened/closed
    bindToQueryStringParam: {
      default: false,
      type: Boolean,
    },
    queryStringParamKey: {
      default: "",
      type: String,
    },
    queryStringParamValue: {
      default: "",
      type: String,
    },
  }

  openValue: boolean
  closeOnFormSubmitValue: boolean
  closeOnBackdropValue: boolean
  referredFromUrlValue: boolean
  redirectUrlValue: string
  bindToQueryStringParamValue: boolean
  queryStringParamKeyValue: string
  queryStringParamValueValue: string
  dialogTarget: HTMLDialogElement
  flashPlaceholderTarget: HTMLDialogElement
  flashHandler: FlashHandler

  hasFlashPlaceholderTarget: boolean
  handleFlashPresentFunction: () => void

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  onDrawerOpenFunction: (event) => void

  connect() {
    if (!this.isPreview && this.openValue) {
      this.open()
    }

    if (this.shouldAppendQueryStringParam()) {
      this.attachQueryStringParam()
    }

    if (this.hasFlashPlaceholderTarget) {
      this.flashHandler = new FlashHandler(this.flashPlaceholderTarget)
      this.handleFlashPresentFunction = this.handleFlashPresent.bind(this)

      const flashMessageContainer = document.querySelector("#flash_message_container")
      const flashMessageWrapper = flashMessageContainer?.querySelector("div")

      if (flashMessageContainer && flashMessageWrapper.clientHeight > 0) {
        this.flashHandler.handleFlashPresent(flashMessageContainer)
      }
    }

    document.addEventListener("turbo:submit-end", this.handleSubmit)
    this.element.addEventListener("click", this.closeBackdrop)
    document.addEventListener("keyup", this.handleKeyup)
    document.addEventListener("flash:present", this.handleFlashPresent.bind(this))
    document.addEventListener("canopy--dialog-component:open", this.open.bind(this))
    document.addEventListener("canopy--dialog-component:close", this.close.bind(this))
  }

  disconnect() {
    if (this.shouldAppendQueryStringParam()) {
      this.detachQueryStringParam()
    }
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
    this.element.removeEventListener("click", this.closeBackdrop)
    document.removeEventListener("keyup", this.handleKeyup)
    document.removeEventListener("flash:present", this.handleFlashPresent.bind(this))
    document.removeEventListener("canopy--dialog-component:open", this.open.bind(this))
    document.removeEventListener("canopy--dialog-component:close", this.close.bind(this))
    this.flashHandler.removeCloseHandlersEvents()

    delete this.flashHandler
  }

  open() {
    this.dialogTarget.showModal()
    this.dialogTarget.classList.remove(
      this.dialogTarget.classList.contains("drawer") ? "translate-x-full" : "translate-y-full",
    )
    requestAnimationFrame(() => {
      this.dialogTarget.classList.remove("opacity-0")
    })

    // Remove focus from the close button
    document.getElementById("close-dialog-button")?.blur()
  }

  close(e) {
    let removeOnClose = true
    // If it's called from a stimulus action
    if (e?.params?.removeOnClose !== undefined) {
      removeOnClose = e.params.removeOnClose
      // If it's called from a CustomEvent
    } else if (e?.detail?.removeOnClose !== undefined) {
      removeOnClose = e.detail.removeOnClose
    }

    // if a dialog is opened from a url and not from an action in app redirect the user to the root url
    if (!this.referredFromUrlValue && this.redirectUrlValue !== "") {
      window.location.href = this.redirectUrlValue
    }

    trigger("drawer:close", {})
    this.dialogTarget.classList.add(
      this.dialogTarget.classList.contains("drawer") ? "translate-x-full" : "translate-y-full",
    )
    requestAnimationFrame(() => {
      this.dialogTarget.close()
      if (removeOnClose) {
        this.element.remove()
      }
      this.dialogTarget.classList.add("opacity-0")
    })

    if (this.shouldAppendQueryStringParam()) {
      this.detachQueryStringParam()
    }
  }

  closeAndCloseFullScreen(e) {
    window.dispatchEvent(new CustomEvent("FullScreen:close", { detail: { reload: true } }))
    this.close(e)
  }

  handleSubmit = (e: CustomEvent) => {
    if (this.closeOnFormSubmitValue && e.detail.success) {
      this.close()
    }
  }

  handleFlashPresent(event: FlashPresentEvent) {
    if (this.dialogTarget.open && this.flashHandler !== undefined) {
      this.flashHandler.handleFlashPresent(event.detail.flashElement)
    }
  }

  // Closes modal when clicking on backdrop
  closeBackdrop = (e: MouseEvent) => {
    if (!this.closeOnBackdropValue) {
      return
    }

    const target = e.target as HTMLDialogElement
    if (target.nodeName === "DIALOG") {
      if (this.hasFlashPlaceholderTarget && target === this.flashPlaceholderTarget) {
        target.close()
      } else {
        this.close()
      }
    }
  }

  // New method to handle keyup events
  handleKeyup = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.close()
    }
  }

  shouldAppendQueryStringParam = () => {
    return this.bindToQueryStringParamValue && this.queryStringParamKeyValue && this.queryStringParamValueValue
  }

  attachQueryStringParam() {
    const url = new URL(window.location)
    if (!url.searchParams.has(this.queryStringParamKeyValue)) {
      url.searchParams.set(this.queryStringParamKeyValue, this.queryStringParamValueValue)
      window.history.pushState(null, "", url.toString())
    }
  }

  detachQueryStringParam() {
    const url = new URL(window.location)
    if (url.searchParams.has(this.queryStringParamKeyValue)) {
      url.searchParams.delete(this.queryStringParamKeyValue)
      window.history.pushState(null, "", url.toString())
    }
  }
}

// Flash Handler Functionality
class FlashHandler {
  constructor(private flashPlaceholderTarget: HTMLDialogElement) {}

  handleFlashPresent(flashElement: HTMLElement) {
    this.showPlaceholder(flashElement)
    this.setupCloseHandlers()
  }

  private showPlaceholder(flashElement: HTMLElement) {
    const { flashPlaceholderTarget } = this

    flashPlaceholderTarget.innerHTML = flashElement.innerHTML

    // Ensure we're not overriding the normal flash behaviour
    if (flashPlaceholderTarget.isConnected && !flashPlaceholderTarget.open) {
      // show the dialog flash
      flashPlaceholderTarget.showModal()
      flashElement.classList.add("opacity-0")
      requestAnimationFrame(() => {
        flashPlaceholderTarget.classList.add("flash-visible")
        // Remove focus from the close button
        flashPlaceholderTarget.focus()
      })
    } else {
      // show the normal flash
      flashElement.classList.add("opacity-100")
    }
  }

  private setupCloseHandlers() {
    const { flashPlaceholderTarget } = this
    const closeButton = flashPlaceholderTarget.querySelector('[data-action="flash#close"]')

    closeButton?.addEventListener("click", this.closeFlashMessages)
    window.setTimeout(this.closeFlashMessages, 6000)
  }

  removeCloseHandlersEvents() {
    const { flashPlaceholderTarget } = this
    const closeButton = flashPlaceholderTarget.querySelector('[data-action="flash#close"]')

    closeButton?.removeEventListener("click", this.closeFlashMessages)
  }

  closeFlashMessages = () => {
    const { flashPlaceholderTarget } = this
    const nonDialogFlash = document.getElementById("flash_message_container") as HTMLDivElement

    nonDialogFlash?.remove()

    flashPlaceholderTarget.innerHTML = ""
    flashPlaceholderTarget.close()
  }
}
