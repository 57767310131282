import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, show } from "../utils"
import { DropzoneFile } from "dropzone"
import {
  EVENT_ADDING_EXISTING_DOCUMENT_RECORD,
  EVENT_FILE_ADDED,
  EVENT_FILE_REMOVED,
  FileChangeEventDetail,
} from "./docupload_controller"
import PanelAccordionGroupComponentController from "./canopy/panel_accordion_group_component_controller"
import DialogComponentController from "./canopy/dialog_component_controller"

// Connects to data-controller="document-uploader-component"
export default class extends Controller {
  static outlets = ["canopy--panel-accordion-group-component", "canopy--dialog-component"]

  static targets = [
    "form",
    "currentFileSignature",
    "documentsForm",
    "fileListItem",
    "hiddenExistingDocumentListItem",
    "submitButton",
    "disabledSubmitButton",
  ]

  static values = {
    confirmSkipUploadDocuments: {
      default: false,
      type: Boolean,
    },
  }

  currentFileSignatureTarget: HTMLInputElement
  formTarget: HTMLFormElement
  documentsFormTarget: HTMLFormElement
  hiddenExistingDocumentListItemTargets: HTMLInputElement[]
  submitButtonTarget: HTMLButtonElement
  disabledSubmitButtonTarget: HTMLButtonElement
  fileListItemTargets: HTMLElement[]
  files: Map<string, DropzoneFile> = new Map()
  filePanelOpenTriggerMap: Map<string, HTMLElement> = new Map()
  panelOpenTriggerFileMap: Map<HTMLElement, DropzoneFile> = new Map()
  savedAndUnsavedFilesMap: Map<string, string> = new Map()

  hasFormTarget: boolean
  hasSubmitButtonTarget: boolean
  hasDisabledSubmitButtonTarget: boolean
  hasCanopyDialogComponentOutlet: boolean

  confirmSkipUploadDocumentsValue: boolean

  canopyPanelAccordionGroupComponentOutlet: PanelAccordionGroupComponentController
  canopyDialogComponentOutlet: DialogComponentController

  submitHandler = this.submitForm.bind(this)

  addFile = (file: DropzoneFile, fileListItem: HTMLElement) => {
    this.files.set(file.upload.uuid, file)
    this.linkFileToPanelTrigger(file, fileListItem)
    this.savedAndUnsavedFilesMap.set(file.upload.uuid, this.initialFilePersistenceStatus(file))
    this.disableEnableSubmitButton()
  }

  allFilesAreSaved = (): boolean => {
    return Array.from(this.savedAndUnsavedFilesMap.values()).every((value) => value === "saved")
  }

  connect = (): void => {
    window.addEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
    window.addEventListener(EVENT_FILE_REMOVED, this.handleFileRemoved)
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("submit", this.submitHandler)
    }
  }

  disableEnableSubmitButton = (): void => {
    if (!this.hasSubmitButtonTarget) return
    this.allFilesAreSaved() ? this.enableSubmit() : this.disableSubmit()
  }

  enableSubmit(): void {
    if (this.hasDisabledSubmitButtonTarget) {
      enable(this.submitButtonTarget)
      show(this.submitButtonTarget)
      hide(this.disabledSubmitButtonTarget)
    } else {
      enable(this.submitButtonTarget)
    }
  }

  disableSubmit(): void {
    if (this.hasDisabledSubmitButtonTarget) {
      disable(this.disabledSubmitButtonTarget)
      hide(this.submitButtonTarget)
      show(this.disabledSubmitButtonTarget)
    } else {
      disable(this.submitButtonTarget)
    }
  }

  disconnect = (): void => {
    window.removeEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
    window.removeEventListener(EVENT_FILE_REMOVED, this.handleFileRemoved)
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener("submit", this.submitHandler)
    }
  }

  handleFileAdded = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = { ...e.detail.file }
    const fileListItem = this.searchForFileListItem(file)
    this.addFile(file, fileListItem)
    fileListItem.click()
  }

  handleFileRemoved = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = e.detail.file
    const fileListItem = this.filePanelOpenTriggerMap.get(file.upload.uuid)

    if (this.currentFileSignatureTarget.value === file.signed_id) {
      this.canopyPanelAccordionGroupComponentOutlet.hidePanelForTrigger(fileListItem)
      this.currentFileSignatureTarget.value = ""
    }

    this.files.delete(file.upload.uuid)
    this.filePanelOpenTriggerMap.delete(file.upload.uuid)
    this.panelOpenTriggerFileMap.delete(fileListItem)
    this.savedAndUnsavedFilesMap.delete(file.upload.uuid)
    this.disableEnableSubmitButton()
    this.removeHiddenExistingDocumentListItem(file.upload.uuid)
  }

  hiddenExistingDocumentListItemTargetConnected = (element: HTMLInputElement) => {
    const fileInfo = JSON.parse(element.dataset.fileInfo)
    const sourceComponent = element.dataset.sourceComponent

    if (sourceComponent === "document-list-item") {
      const fileListItem = this.searchForFileListItem(fileInfo)
      this.addFile(fileInfo, fileListItem)
      return
    }

    setTimeout(() => {
      window.dispatchEvent(new CustomEvent(EVENT_ADDING_EXISTING_DOCUMENT_RECORD, { detail: fileInfo }))
    }, 0)
  }

  initialFilePersistenceStatus = (file: DropzoneFile): boolean => {
    const alreadySaved = !!this.hiddenExistingDocumentListItemTargets.find((el) => el.value === file.upload.uuid)
    return alreadySaved ? "saved" : "unsaved"
  }

  linkFileToPanelTrigger = (file: DropzoneFile, fileListItem: HTMLElement): void => {
    this.filePanelOpenTriggerMap.set(file.upload.uuid, fileListItem)
    this.panelOpenTriggerFileMap.set(fileListItem, file)
  }

  onDocumentSaved = (): void => {
    const currentFile = Array.from(this.files.values()).find(
      (file) => file.signed_id === this.currentFileSignatureTarget.value,
    )
    this.savedAndUnsavedFilesMap.set(currentFile.upload.uuid, "saved")

    this.disableEnableSubmitButton()
  }

  searchForFileListItem = (file: DropzoneFile): Element | undefined => {
    return this.fileListItemTargets.find((el) => el.dataset.uniqueId === file.upload.uuid)
  }

  showLinkedFileInDynamicPanel = (e: MouseEvent): void => {
    const fileListItem = e.currentTarget as HTMLElement
    const uploadId = fileListItem.dataset.uniqueId
    const file = this.files.get(uploadId)
    const fileSignature = file.signed_id
    if (this.currentFileSignatureTarget.value === fileSignature) return

    this.currentFileSignatureTarget.value = fileSignature
    this.documentsFormTarget.requestSubmit()
  }

  private submitForm(e) {
    if (
      this.savedAndUnsavedFilesMap.size === 0 &&
      this.confirmSkipUploadDocumentsValue &&
      this.hasCanopyDialogComponentOutlet
    ) {
      e.preventDefault()
      this.canopyDialogComponentOutlet.open()
    }
  }

  private removeHiddenExistingDocumentListItem(uuid: string) {
    this.hiddenExistingDocumentListItemTargets.forEach((el: HTMLInputElement): void => {
      if (el.value === uuid) {
        el.remove()
      }
    })
  }
}
